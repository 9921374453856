<template>
  <!-- DATA - RIGHT SCROLLBAR -->
  <!-- :max-height="$vuetify.breakpoint.smAndDown ? 110 : 630" -->
  <v-card elevation="0" class="overflow-y-auto" color="#1a1b1d" style="margin-left: 5px" min-height="630"
    :max-height="$vuetify.breakpoint.smAndDown ? `100%` : `630`" :style="$vuetify.breakpoint.smAndDown
      ? `border-left: `
      : `border-left:1px solid gray`
      ">
    <v-card-text>
      <div>
        <div>
          <h2 class="HeadTextStyle" :style="fontSize">About me</h2>
        </div>
        <!-- <p class="infos" style="font-size: 1.1em; font-weight: 500">
          Front-End Developer
        </p> -->

        <div class="experience box">
          <p class="desc-1 about">
            As a dedicated developer, I embrace challenges and actively seek personal and professional development. I
            believe that a well-organized team with exceptional communication is essential for achieving success. With
            my skills and enthusiasm, I am driven to contribute and make a positive impact on a dynamic and ambitious
            company.
            <br />
          </p>
        </div>

        <div class="section myservices">
          <div class="container" style="padding-top: 20px">
            <div class="experience box">
              <p class="headText" style="font-size: 1.1em">Skills</p>
              <p class="desc-1">
                <i class="grayicon fas fa-check"></i> HTML <br />
                <i class="grayicon fas fa-check"></i> CSS / SCSS /
                Bootstrap<br />
                <i class="grayicon fas fa-check"></i> JavaScript <br />
                <i class="grayicon fas fa-check"></i> JQuery <br />
                <i class="grayicon fas fa-check"></i> Vue (Vue router, Vuex,
                Pinia)
                <br />
                <i class="grayicon fas fa-check"></i> Vuetify<br />
                <i class="grayicon fas fa-check"></i> Firebase
                (Backend-as-a-Service) <br />
                <i class="grayicon fas fa-check"></i> Git / GitHub <br />
                <i class="grayicon fas fa-check"></i> Figma design to code <br />
                <i class="grayicon fas fa-check"></i> Wordpress <br />
              </p>
            </div>
            <div class="experience box">
              <div v-for="(cv, i) in lista" :key="i">
                <p class="download">
                  Looking for my CV ? You can see and download it
                  <a :href="`${cv.url}`" target="_blank" class="downloadLink">
                    here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from "firebase/app";
export default {
  data() {
    return {
      lista: [],
    };
  },
  beforeMount() {
    this.lista = [];
    let url = "";
    firebase
      .storage()
      .ref("cv/Igor_Vasic_CV.pdf")
      .getDownloadURL()
      .then((URL_Adresa) => {
        url = URL_Adresa;
      })
      .then(() => {
        this.lista.push({ url });
      });
  },
  computed: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return { "font-size": "1.35em !important" };
        default:
          return { "font-size": "1.4em" };
      }
    },
  },
};
</script>

<style></style>
